import rare_pc from '@assets/images/home/rare5.png'
import epic_pc from '@assets/images/home/epic5.png'
import HHJC_L_card_pc from '@assets/images/home/hyper5.png'
import lucky_pc from '@assets/images/home/lucky5.png'
import uncommon_pc from '@assets/images/home/uncommon5.png'
import mystery_pc from '@assets/images/home/mystery5.png'

import dash_kuang1 from '@assets/images/home/dash-kuang0.png';
import hmTwo from '@assets/images/home/hmTwo.png';
import mhTwo from '@assets/images/home/mhTwo.png';
import epic from '@assets/images/home/epic@2x.png'
import hyper from '@assets/images/home/hyper@2x.png'
import rare from '@assets/images/home/rare@2x.png'
import lucky from '@assets/images/home/lucky@2x.png'

import rare5 from '@assets/images/h5/rare5.png';
import lucky5 from '@assets/images/h5/lucky5.png';
import uncommon5 from '@assets/images/h5/uncommon5.png';
import epic5 from '@assets/images/h5/epic5.png';
import mystery5 from '@assets/images/h5/mystery5.png';
import hyper5 from '@assets/images/h5/hyper5.png';
import rare2 from '@assets/images/h5/rare2.png';
import lucky2 from '@assets/images/h5/lucky2.png';
import dash5 from '@assets/images/h5/dash5.png';
import uncommon2 from '@assets/images/h5/uncommon2.png';
import epic2 from '@assets/images/h5/epic2.png';
import mystery2 from '@assets/images/h5/mystery2.png';
import hyper2 from '@assets/images/h5/hyper2.png';
import dash2 from '@assets/images/h5/dash2.png';


export const getAddress = (address: string) => {
    let first = address.substring(0, 5);
    let last = address.substring((address.length - 5));
    return `${first}...${last}`
}


export const stringCopy = (text: string, cb?: Function) => {
    try {
        let url = text;//拿到想要复制的值
        let copyInput = document.createElement('input');//创建input元素
        document.body.appendChild(copyInput);//向页面底部追加输入框
        copyInput.setAttribute('value', url);//添加属性，将url赋值给input元素的value属性
        copyInput.select();//选择input元素
        document.execCommand("Copy");//执行复制命令
        copyInput.remove();//删除动态创建的节点 
        cb && cb(true)
    } catch (error) {
        cb && cb(false)
    }

}


export const getImage = (urls: string, imageUrl: any) => {
    if (urls) { 
        // const logoList = item.images.split(',');
        // const logo = logoList[logoList.length - 1];
        if (imageUrl.includes('nfts')) {
            return `${urls}/${imageUrl}`
        } else {
            return `${urls}/${imageUrl}/preview.png`
        }
    } else {
        return ''
    }
}

export const getBgImage = (platform: 'pc' | 'h5', level: string, type: 5 | 2) => {
    if (platform === 'h5') {
        if (type == 5) {
            if (level === 'rare') {
                return rare5
            } else if (level === 'lucky') {
                return lucky5
            } else if (level === 'uncommon') {
                return uncommon5
            } else if (level === 'epic') {
                return epic5
            } else if (level === 'mystery') {
                return mystery5
            } else if (level === 'dash') {
                return dash5
             } else {
                return hyper5
            }
        } else {
            if (level === 'dash') {
                return dash2
            } if (level === 'rare') {
                return rare2
            } else if (level === 'lucky') {
                return lucky2
            } else if (level === 'uncommon') {
                return uncommon2
            } else if (level === 'epic') {
                return epic2
            } else if (level === 'mystery') {
                return mystery2
            } else {
                return hyper2
            }
        }
    } else {
        if (type == 5) {
            if (level === 'rare') {
                return rare_pc
            } else if (level === 'lucky') {
                return lucky_pc
            } else if (level === 'uncommon') {
                return uncommon_pc
            } else if (level === 'epic') {
                return epic_pc
            } else if (level === 'mystery') {
                return mystery_pc
            } else {
                return HHJC_L_card_pc
            }
        } else {
            if (level === 'dash') {
                return dash_kuang1
            } else if (level === 'uncommon') {
                return hmTwo
            } else if (level === 'mystery') {
                return mhTwo
            } else if (level === 'lucky') {
                return lucky
            } else if (level === 'epic') {
                return epic
            } else if (level === 'rare') {
                return rare
            } else {
                return hyper
            }
        }
    }
}