import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '@store/index';
import { create_order, set_order_paid, set_orders_setTxid, cancel_order, ReasonEnum, get_orders_mintList, get_collections_address, set_repurchase_order, set_all_mints, set_recent } from '../api/order'
import { message } from 'antd';
import { seaport_listing, getTokens } from '../api/seaport'
import { Seaport } from "@opensea/seaport-js";
import { ethers } from 'ethers';
import { ItemType } from '@opensea/seaport-js/lib/constants';
import axios from 'axios'
import image1 from '../assets/images/home/GHC1_S_card.png';
import image2 from '../assets/images/home/GHC2_S_card.png'
import image3 from '../assets/images/home/GHC3_S_card.png'
import image4 from '../assets/images/home/GHC4_S_card.png';
import image0 from '../assets/images/home/GHC0_S_card.png'
import { config } from '../config' 




export { ReasonEnum } from '../api/order';


const OrderClice = createSlice({
    name: 'OrderModule',
    initialState: {
        orderInfo: null,
        dialogVisible: false,
        NFTs: [],
        checkData: [],
        HMTotalPrice: 0,
        MHTotalPrice: 0
    },
    reducers: {
        setOrderInfo(state, { payload }) {
            state.orderInfo = payload
        },
        setDialogVisible(state, { payload }) {
            state.dialogVisible = payload
        },
        setNFTs(state, { payload }) {
            state.NFTs = payload
        },
        setCheckData(state, { payload }) {
            state.checkData = payload
        },
        setHMTotalPrice(state, { payload }) {
            state.HMTotalPrice = payload
        },
        setMHTotalPrice(state, { payload }) {
            state.MHTotalPrice = payload
        }
    },
});

export const { setOrderInfo, setDialogVisible, setNFTs, setCheckData, setHMTotalPrice, setMHTotalPrice } = OrderClice.actions;


// BSC 的下单流程还是和 Solana  一样：
//先创建订单 -> 
//然后服务器返回订单的金额 -> 
//前端调用 eth_signTransaction 方法创建并签署一个转帐交易 -> 
//将签名后的交易数据返回给服务器 -> 
//前端调用 eth_sendRawTransaction 方法发送交易 -> 
//设置订单为支付状态。
export const create_order_func = (quantity: string, cb?: (data: any, status: any) => void): AppThunk => async (dispatch, getState) => {

    const chain: any = getState().globalModule.chain
    if (!config.chainIds.includes(chain?.id)) {
        message.error("Wrong network")
        return
    } 
    // const orderId = localStorage.getItem("orderId");
    // const orderTransactionHash = localStorage.getItem("orderTransactionHash"); 
    // if (orderId && orderTransactionHash) {
    //     message.error("存在尚未完成订单")
    //     return;
    // }
    const issuance: any = getState().globalModule.issuance; 
    if (issuance && issuance.id) {
        dispatch(setOrderInfo(null));
        dispatch(setDialogVisible(false));
        const res: any = await create_order(issuance.id, quantity);
        if (res.success && res.data) {
            const price = Number(res.data.order.actualPrice) + 0.000005;
            dispatch(setOrderInfo({ ...res.data, totalPrice: price }));
            cb && cb(res.data, true);
        } else {
            // <!-- 库存不足提示 --> 
            cb && cb(res.code, false)
        }
    }
}


const toHex = (tokenId: string) => {
    const hexTokenId = window.web3.utils.toHex(tokenId);

    if (hexTokenId.length < 66) {
        const lastStr = hexTokenId.substring(2);
        let z0 = '';
        for (let index = 0; index < (64 - lastStr.length); index++) {
            z0 += '0'
        }
        // console.log(hexTokenId, lastStr, `0x${z0}${lastStr}`)
        return `0x${z0}${lastStr}`
    } else {
        return hexTokenId
    }
}

//获取 NFTs
export const get_NFTs_func = (chainId: number, params: string[], f?: () => void): AppThunk => async (dispatch, getState) => {
    if (!config.chainIds.includes(chainId)) {
        message.error("Wrong network")
        f && f();
        return
    } 
    
    const result = await getTokens(chainId, params);
    const recentList = await set_recent()
    if (result && result.status == 200) {
        const nftData: any = result.data.result
        const allArr: any = []
        const hmList: any = []

        let MHTotalPrice: number = 0;
        nftData?.forEach((item: any, index:number) => {
            const newName: any = item.data.name
            if (newName === null) {
                return
            }
            const newNumber: any = newName.substring(0, 4)
            const lastNumber: any = newName.substring(newName.length - 5, newName.length)
            if (item.symbol === "GHC") {
                const hexTokenId = toHex(item.tokenId);
                hmList.push(hexTokenId)
            } else if (item.symbol === "ceshi" || item.symbol === "GMB") {
                const price = item.data.attributes.find((item2: any) => {
                    return item2.trait_type === 'game value'
                })
                MHTotalPrice += Number(price ? price.value : 0)
                allArr.push({ game_value: (price ? price.value : 0), image: item.image, name: newNumber + "..." + lastNumber, attributes: item.data.attributes, bgImage: '', tokenId: item.tokenId, manghe: 1, symbol: "GMB" })
            }
        });
        // console.log(hmList, "======hmList")
        const mintList: any = await set_all_mints(hmList);
        // let newArr: any = []
        let HMTotalPrice: number = 0
        const newList = mintList.data.sort((a: any, b: any) => {
            return Number(a.recyclingPrice) > Number(b.recyclingPrice) ? -1 : 1
        }).map((item: any, index: number) => {
            const nftDataItem = nftData.find((ele: any) => {
                return toHex(ele.tokenId) === item.mint
            });
            if (nftDataItem) {
                // recentList.data
                const keys = Object.keys(recentList.data)
                const hasState = keys.includes(item.mint) && recentList.data[item.mint] != 3
                // if (!hasState) {
                //     newArr.push(item.mint)
                // }
                const newName: any = nftDataItem.data.name
                const newNumber: any = newName.substring(0, 4)
                const lastNumber: any = newName.substring(newName.length - 5, newName.length)
                const bgLevel: any = nftDataItem.data.attributes[1].value
                let bgImage: any = ''
                if (bgLevel === 'lucky') {
                    bgImage = image1
                } else if (bgLevel === 'uncommon') {
                    bgImage = image2
                } else if (bgLevel === 'rare') {
                    bgImage = image3
                } else if (bgLevel === 'epic') {
                    bgImage = image4
                } else {
                    bgImage = image0
                }

                HMTotalPrice += Number(item.recyclingPrice)
                return {
                    ...item,
                    image: nftDataItem.image,
                    name: newNumber + "..." + lastNumber,
                    attributes: nftDataItem.data.attributes,
                    bgImage: bgImage,
                    tokenId: toHex(nftDataItem.tokenId),
                    manghe: 0,
                    symbol: "GHC",
                    status: hasState
                }
            }
        }) 

        dispatch(setNFTs([...newList, ...allArr]))
        // const arr = [...newList].slice(0, 30)
        dispatch(setHMTotalPrice(HMTotalPrice.toFixed(4)))
        dispatch(setMHTotalPrice(MHTotalPrice.toFixed(4)))

        // dispatch(setCheckData(newArr))
        // const priceTotal: any = await set_all_mints(newArr);
        // cb && cb(priceTotal.data)
    }

    f && f();
}

export const seaport_listing_func = (account: string, chainId: number, selectArr: any, provider: any, cb?: (data: any, type: number) => void): AppThunk => async (dispatch, getState) => {
 
    if (!config.chainIds.includes(chainId)) {
        message.error("Wrong network")
        return
    }
    const param: any = selectArr.map((item: any) => {
        return window.web3.utils.toHex(item);
    })
    const mintList = await set_all_mints(param);
    const seaportProvider = new ethers.providers.Web3Provider(provider, 'any')
    const seaport: any = new Seaport(seaportProvider);

    const addressList: { id: string, address: string }[] = []

    const ghcMintList = mintList.data.filter((item: any) => {
        return item.symbol === "GHC"
    })

    if (ghcMintList.length <= 0) {
        // 是否为空
        return;
    }
    for (let index = 0; index < ghcMintList.length; index++) {
        const element = ghcMintList[index];
        if (!addressList.find((item) => {
            return item.id == element.collectionId;
        })) {
            const res = await get_collections_address(element.collectionId)
            addressList.push({ id: element.collectionId, address: res.data })
        }
    }

    const offer = ghcMintList.map((item: any) => {
        const token = addressList.find((addressObj) => {
            return addressObj.id == item.collectionId
        })?.address
 
        return {
            identifier: item.mint,
            itemType: ItemType.ERC721,
            token: token,
        }
    })



    const consideration = ghcMintList.map((item: any) => {
        return {
            amount: ethers.utils.parseEther(item.recyclingPrice).toString(),
            recipient: account
        }
    })

    const params = {
        "offer": offer,
        "consideration": consideration
    }
    try {
        // console.log(params, "======paramsparams")
        const { executeAllActions } = await seaport.createOrder(params);
        const order = await executeAllActions();   //卖方的
        //上架
        const result: any = await seaport_listing(chainId, order);
        // console.log(result, "========result")
        if (result && result.success) {
            //成功  写入到服务器  逻辑待处理
            const orderResult = await set_repurchase_order(JSON.stringify(order));
            // console.log(orderResult, "====orderResult")
            cb && cb(true, 1)
        } else {
            //提示
            cb && cb(true, 0)
        }
    } catch (error) {
        console.log(error)
    }
}

export const get_result_func = (chainId: number, orderId: string, metadata: string, cb?: (data: any, type: any, gametaValue: any, floorValue: any) => void): AppThunk => async (dispatch, getState) => {

    if (!config.chainIds.includes(chainId)) {
        message.error("network error")
        return
    }
    const mintList = await get_orders_mintList(orderId, 1, 2000);
    let items = mintList.data
    if (items.length === 1) {
        let str = items[0].symbol
        let newStr = str.substr(0, 3)
        let metafile = metadata + '/' + items[0].metadata
        axios({
            url: metafile,
            method: 'get',
            headers: { "Content-Type": "application/json" },
        }).then(resp => {
            const name = items[0].name
            const newNumber = name.substring(0, 2)
            const lastNumber = name.substring(name.length - 6, name.length)
            if (newStr === 'GMB') {
                // 盲盒
                let obj = { image: resp.data.image, manghe: 1, newName: newNumber + '...' + lastNumber, priceInGame: items[0].priceInGame }
                cb && cb(obj, true, null, null)
            } else if (newStr === 'GHC') {
                // 河马
                const bgLevel = resp.data.attributes[1].value
                let bgImage = ''
                if (bgLevel === 'lucky') {
                    bgImage = require('../assets/images/home/GHC1_S_card.png')
                } else if (bgLevel === 'uncommon') {
                    bgImage = require('../assets/images/home/GHC2_S_card.png')
                } else if (bgLevel === 'rare') {
                    bgImage = require('../assets/images/home/GHC3_S_card.png')
                } else if (bgLevel === 'epic') {
                    bgImage = require('../assets/images/home/GHC4_S_card.png')
                } else {
                    bgImage = require('../assets/images/home/GHC0_S_card.png')
                }
                let obj = { image: resp.data.image, manghe: 0, newName: newNumber + '...' + lastNumber, bgImage: bgImage, recyclingPrice: items[0].recyclingPrice }
                cb && cb(obj, true, null, null)
            }
        }).catch((e)=>{
            
        })
    } else {
        let gmbList: any = []
        let hmPrice: number = 0
        let mhPrice: number = 0
        for (let i = 0; i < items.length; i++) {
            let metafile = metadata + '/' + items[i].metadata
            const resp = await axios({
                url: metafile,
                method: 'get',
                headers: { "Content-Type": "application/json" },
            })
            let str = items[i].symbol
            let newStr = str.substr(0, 3)
            let name = items[i].name
            const newNumber = name.substring(0, 2)
            const lastNumber = name.substring(name.length - 6, name.length)
            const obj = {
                image: resp.data.image,
                recyclingPrice: Number(items[i].recyclingPrice),
                priceInGame: Number(items[i].priceInGame),
                mint: items[i].mint,
                status: items[i].status,
                name: newNumber + '...' + lastNumber,
                manghe: -1,
                bgImage: '',
                hmPrice: 0,
                mhPrice: 0
            }
            if (newStr === 'GHC') {
                obj.manghe = 0
                let bgLevel = resp.data.attributes[1].value
                if (bgLevel === 'lucky') {
                    obj.bgImage = require('../assets/images/home/GHC1_S_card.png')
                } else if (bgLevel === 'uncommon') {
                    obj.bgImage = require('../assets/images/home/GHC2_S_card.png')
                } else if (bgLevel === 'rare') {
                    obj.bgImage = require('../assets/images/home/GHC3_S_card.png')
                } else if (bgLevel === 'epic') {
                    obj.bgImage = require('../assets/images/home/GHC4_S_card.png')
                }  else {
                    obj.bgImage = require('../assets/images/home/GHC0_S_card.png')
                }
                let newPrice = Number(items[i].recyclingPrice)
                hmPrice += newPrice
                obj.hmPrice = hmPrice
                gmbList.push(obj)
            } else {
                obj.manghe = 1
                let recy = Number(items[i].priceInGame)
                mhPrice += recy
                obj.mhPrice = mhPrice
                gmbList.push(obj)
            }
        }
        // 盲盒总价
        const gametaValue = Math.max.apply(Math, gmbList.map((item: any) => { return item.mhPrice }))
        // 河马总价
        const floorValue = Math.max.apply(Math, gmbList.map((item: any) => { return item.hmPrice }))
        gmbList.sort((a: any, b: any) => { return (a.recyclingPrice) < (b.recyclingPrice) ? 1 : -1 })
        cb && cb(gmbList, false, gametaValue, floorValue)
    }
}

export enum PayDoneStatusEnum {
    loading = 0,
    success = 1,
    failed = 2,
    payFailed = 3,
    paidFailed = 4,
    invalid = 5
}

export const payDone_func = (cb?: (status: PayDoneStatusEnum) => void, f?: Function): AppThunk => async (dispatch, getState) => {

    const orderInfo: any = getState().orderModule.orderInfo; 
    const txnParams = {
        type: "0x0",
        from: orderInfo.order.wallet,
        to: orderInfo.payee,
        value: window.web3.utils.toWei(orderInfo.order.actualPrice, "ether"),
        data: "0x",
        gasLimit: 21000,
    };
    try {
        const sendTransaction_result = await window.web3.eth.sendTransaction(txnParams, (error: Error, hash: string) => {
            if (error) {
                //支付失败
                dispatch(cancel_order_func(ReasonEnum.paymentFailed));
                cb && cb(PayDoneStatusEnum.payFailed)
                throw error
            }
            cb && cb(PayDoneStatusEnum.loading)
        });
        localStorage.setItem("orderId", orderInfo.order.id);
        localStorage.setItem("orderTransactionHash", sendTransaction_result.transactionHash);

        try {
            const paidResult: any = await set_order_paid(orderInfo.order.id, sendTransaction_result.transactionHash); 
            if (paidResult.success && paidResult.data) {
                if (paidResult.data === 2) {
                    cb && cb(PayDoneStatusEnum.invalid)
                } else if (paidResult.data === 1) {
                    localStorage.removeItem("orderId");
                    localStorage.removeItem("orderTransactionHash");
                    cb && cb(PayDoneStatusEnum.success)
                }
            } else {
                localStorage.removeItem("orderId");
                localStorage.removeItem("orderTransactionHash");
                cb && cb(PayDoneStatusEnum.paidFailed)
            }
        } catch (error) {
            cb && cb(PayDoneStatusEnum.failed);
            //paid 5秒执行存储
            setTimeout(set_order_paid_polling, 1000 * 5);
        } 
    } catch (error: any) {
        if (error.code !== 4011) {
            message.error(error.message);
        }
        console.log("钱包请求未成功 sendTransaction error", error);

        // <!-- 钱包请求未成功 -->
        cb && cb(PayDoneStatusEnum.payFailed);

    }

    f && f();
}

export const set_order_paid_polling = async () => {
    const orderId = localStorage.getItem("orderId");
    const orderTransactionHash = localStorage.getItem("orderTransactionHash");
    if (orderId && orderTransactionHash) {
        try {
            const paidResult: any = await set_order_paid(orderId, orderTransactionHash);
            if (paidResult.success && paidResult.data) {
                localStorage.removeItem("orderId");
                localStorage.removeItem("orderTransactionHash");
            } else {
                localStorage.removeItem("orderId");
                localStorage.removeItem("orderTransactionHash");
            }
        } catch (error) {
            setTimeout(set_order_paid_polling, 1000 * 5);
        }
    }
}





export const cancel_order_func = (reason: ReasonEnum, cb?: Function): AppThunk => async (dispatch, getState) => {
    const orderInfo: any = getState().orderModule.orderInfo; 
    if (orderInfo) {
        const res: any = await cancel_order(orderInfo.order.id, reason);
        if (res.success && res.data) {
            dispatch(setOrderInfo(null))
            cb && cb()
        }
    }
}
 
export default OrderClice.reducer;