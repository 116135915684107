

import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '@store/index';
import { getActivities, ActivitiesType, postBoxReceiveNFT, postAwardLottery, getMintCount, getAwardLotteryCount, getReceiveNft, getNFTQueryStatus, getAwardRecordUser } from '@api/activities';
import { AbiItem } from 'web3-utils';
import { config } from '@/config';
import { message } from 'antd';
import { getTokens } from '@/api/seaport'; 

export { ActivitiesType } from '@api/activities';

const ActivitiesClice = createSlice({
    name: 'ActivitieModule',
    initialState: {
        activitiesInfo: null,
        // 盲盒
        contractBalance: 0,
        contractBalanceList: [],
        boxContractBalance: 0,
        boxContractAddressList: [],
        mintCount: 0,

        activitiesInfo2: null


    },
    reducers: {
        setActivitiesInfo(state, { payload }) {
            state.activitiesInfo = payload
        },
        setContractBalance(state, { payload }) {
            state.contractBalance = payload
        },
        setBoxContractBalance(state, { payload }) {
            state.boxContractBalance = payload;
        },
        setBoxContractAddressList(state, { payload }) {
            state.boxContractAddressList = payload;
            state.boxContractBalance = payload.length;
        },
        setContractBalanceList(state, { payload }) {
            state.contractBalanceList = payload;
            state.contractBalance = payload.length;
        },
        setMintCount(state, { payload }) {
            state.mintCount = payload
        },
        setActivitiesInfo2(state, { payload }) {
            state.activitiesInfo2 = payload
        }
    }
});

export const { setActivitiesInfo, setContractBalance, setBoxContractBalance, setBoxContractAddressList, setContractBalanceList, setMintCount, setActivitiesInfo2 } = ActivitiesClice.actions;



/**
 * 获取活动信息
 * @param type 
 * @returns 
 */
export const getActivities_func = (type: ActivitiesType, chainId: number, params: string[], cb?: Function, f?: Function): AppThunk => async (dispatch, getState) => {
    const chain: any = getState().globalModule.chain
    // if (params. !config.chainIds.includes(chain?.id)) {
    //     message.error("Wrong network")
    //     return
    // }

    const result: any = await getActivities(type);

    if (type == ActivitiesType.luckDrawActivity) { 
        if (result.success && result.data) {
            let contractAddress: any
            if (params.length > 0) {
                const res = await getTokens(chainId, params);
                if (res && res.status == 200) {
                    const nftData: any[] = res.data.result || [];
                    if (result.data.contractAddress) {
                        contractAddress = nftData.filter((item: any) => {
                            return item.address.toLowerCase() === result.data.contractAddress.toLowerCase()
                        }); 
                        dispatch(setContractBalanceList(contractAddress));
                    } else {
                        dispatch(setContractBalanceList([]));
                    }
                }
            }

            await dispatch(setActivitiesInfo2(result.data));
            cb && cb(contractAddress)
        }
    } else {
        if (result.success && result.data) {
            await dispatch(setActivitiesInfo(result.data))

            if (params.length > 0) {
                const res = await getTokens(chainId, params);
                if (res && res.status == 200) {
                    const nftData: any[] = res.data.result || [];
                    if (result.data.contractAddress) {
                        const contractAddress = nftData.filter((item: any) => {
                            return item.address.toLowerCase() === result.data.contractAddress.toLowerCase()
                        });
 
                        dispatch(setContractBalanceList(contractAddress));
                    } else {
                        dispatch(setContractBalanceList([]));
                    }
                }

                if (type == ActivitiesType.getHipp) {
                    dispatch(mintCount_func());
                } else {




                    if (res && res.status == 200) {
                        const nftData: any[] = res.data.result || [];

                        if (result.data.boxContractAddress) {
                            const boxContractAddress = nftData.filter((item: any) => {
                                return item.address.toLowerCase() === result.data.boxContractAddress.toLowerCase()
                            }); 
                            dispatch(setBoxContractAddressList(boxContractAddress));
                        } else {
                            dispatch(setBoxContractAddressList([]));
                        }
                    }

                }
            }else{
                if (type == ActivitiesType.getHipp) {
                    dispatch(mintCount_func());
                }
            }
        }
    }
    f && f()
}


export const get_NFTs_func = (chainId: number, params: string[], cb?: (data: any) => void): AppThunk => async (dispatch, getState) => {
    if (!config.chainIds.includes(chainId)) {
        message.error("Wrong network")
        return
    }

    const result = await getTokens(chainId, params);
    if (result && result.status == 200) {
        const nftData: any = result.data.result
    }
}


export enum NFTType {
    contractAddress = 1,
    boxContractAddress = 2
}
export const getBalanec_func = (tokenAddress: string, type: NFTType): AppThunk => async (dispatch, getState) => {

    const abi: AbiItem = {
        // anonymous?: boolean;
        constant: true,
        inputs: [{ "name": "_owner", "type": "address", "internalType": "address" }],
        name: "balanceOf",
        outputs: [{ "name": "balance", "type": "uint256", "internalType": "address" }],
        // payable?: boolean;
        // stateMutability?: StateMutabilityType;
        type: "function"
        // gas?: number;
    }
    const accounts = await window.web3.eth.getAccounts()
    const contract = new window.web3.eth.Contract([abi], tokenAddress);
    const balance = await contract.methods.balanceOf(accounts[0]).call(); 
    // if (type === NFTType.contractAddress) {
    //     dispatch(setContractBalance(balance));
    // } else {
    //     dispatch(setBoxContractBalance(balance));
    // }

}

/**
 * NFT 抽奖
 * @param mint 
 * @param cb 
 * @returns 
 */
export const awardLottery_func = (mint: string, cb?: (code: string) => void, f?: Function): AppThunk => async (dispatch, getState) => {
    const result: any = await postAwardLottery(mint);
    if (result) {
        cb && cb(result.data)
    }
    f && f()
}

function log(time: number) {
    return new Promise((resolve: any, reject) => {
        setTimeout(function () {
            console.log(time)
            resolve()
        }, time)
    })
}
/**
 * 开盲盒
 * @param mint 
 * @returns 
 */
export const boxReceiveNFT_func = (mint: string, chainId: any, params: any, cb?: (code: string, list?: any) => void): AppThunk => async (dispatch, getState) => {

    const activitiesInfo: any = getState().activitiesModule.activitiesInfo;

    const result: any = await postBoxReceiveNFT(mint);
    if (result) {
        ;
        await loopGetNFT_func(result.code, activitiesInfo, chainId, params, cb);
        // cb && cb(result.code, contractAddress)
    }
}

const loopGetNFT_func = async (code: any, activitiesInfo: any, chainId: any, params: any, cb?: (code: string, list?: any) => void) => {
    await log(5000)
    let contractAddress: any = [];
    const res = await getTokens(chainId, params);
    if (res && res.status == 200) {
        const nftData: any[] = res.data.result || [];
        // console.log(res.data, nftData, activitiesInfo?.contractAddress, "======nftData")
        if (activitiesInfo?.contractAddress) {
            contractAddress = nftData.filter((item: any) => {
                return item.address.toLowerCase() === activitiesInfo?.contractAddress.toLowerCase()
            });

            if (contractAddress && contractAddress.length > 0) {
                cb && cb(code, contractAddress)
            } else {
                await loopGetNFT_func(code, activitiesInfo, chainId, params, cb)
            }
        }
    }
}
/**
 * 查询用户mint次数
 * @param cb 
 * @returns 
 */
export const mintCount_func = (cb?: (code: string) => void): AppThunk => async (dispatch, getState) => {
    const result: any = await getMintCount();
    if (result && result.code === "0") {
        cb && cb(result.data)
        dispatch(setMintCount(result.data))
    }
}
/**
 * 通过nft的token查询当天抽奖次数
 * @param mint 
 * @param cb 
 * @returns 
 */
export const awardLotteryCount_func = (mint: string, cb?: (data: any) => void): AppThunk => async (dispatch, getState) => {
    const result: any = await getAwardLotteryCount(mint);
    if (result) {
        cb && cb(result.data)
    }
}
/**
 * 领取happo vip nft
 * @param mint 
 * @param cb 
 * @returns 
 */
export const receiveNft_func = (cb?: (code: string) => void): AppThunk => async (dispatch, getState) => {
    const result: any = await getReceiveNft();
    if (result) {
        if (result.code === "SI083") {
            cb && cb(result.code)
        } else {
            cb && cb(result.data)
        }
    }
}


export const getNFTQueryStatus_func = (cb?: (code: string) => void): AppThunk => async (dispatch, getState) => {
    const result: any = await getNFTQueryStatus();
    if (result) {
        cb && cb(result.data)
    }
}


export const getAwardRecordUser_func = (cb?: (code: any) => void): AppThunk => async (dispatch, getState) => {
    const result: any = await getAwardRecordUser(0, 1000);
    if (result) {
        cb && cb(result.data)
    }
}

export default ActivitiesClice.reducer;