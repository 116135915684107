import React, { useEffect, useState } from 'react'
import { message } from "antd";
import '@particle-network/connect-react-ui/dist/index.css';
import { useAccount, useConnectKit, useParticleProvider } from "@particle-network/connect-react-ui";
import Web3 from 'web3'
import { isEVMProvider } from "@particle-network/connect";
import { useDispatch, useSelector } from 'react-redux';
import { disConnect_Func, register_Func, setToken } from '@/features/loginClice';
import bs58 from "bs58";
import { config } from '@/config';
import { seChain, setBalance } from '@/features/globalClice';
import { RootState } from '@/store';




function Index() {
  const account: string | undefined = useAccount();
  const provider: any = useParticleProvider();

  const dispatch = useDispatch();



  const [web3, setWeb3] = useState<any>(window.web3);
  const connectKit = useConnectKit();
  const { chain, token } = useSelector((state: RootState) => {
    return {
      chain: state.globalModule.chain,
      token: state.loginModule.token
    }
  })

  useEffect(() => {
    if (provider && isEVMProvider(provider)) {
      console.log("set web3");
      window.web3 = new Web3(provider as any)
      setWeb3(new Web3(provider as any));
    }
  }, [provider]);

  useEffect(() => { 
    connectKit.on('connect', (provider) => {

    });
    connectKit.on('disconnect', () => {
      // localStorage.removeItem('token')
      dispatch(disConnect_Func())
      dispatch(seChain(null))
    });
    connectKit.on('chainChanged', (chain) => { 
      dispatch(seChain(chain));
    });
    connectKit.on('accountsChanged', (accounts) => { 
      localStorage.removeItem("token");  
      // getChainId();
    });

  }, [])

  useEffect(() => {

    const token = localStorage.getItem('token'); 
    if (account && provider && (isEVMProvider(provider)) && !token) {
      setTimeout(() => {
        web3.eth.personal
          .sign(config.signMessage, account, "")
          .then((result: string) => {
            dispatch(register_Func(account, result, 'bsc'));
            getChainId();
          })
          .catch((error: any) => {
            // console.log(error,"====")
            connectKit.disconnect()
          });
      }, 600)

    } else if (account && !token) {
      try {
        if ("signMessage" in provider) {
          provider.signMessage(Buffer.from(config.signMessage)).then((result: any) => {
            dispatch(register_Func(account, bs58.encode(result), 'solana'));
            getChainId();
          })
        }
      } catch (error) {
        console.log(error)
      }
    }

    if (account) {
      getChainId();
    }
  }, [account, token])

  useEffect(() => {
    if (account) {
      getBalance();
    }
  }, [chain, account])

  const getBalance = async () => {
    if (account) {
      const balance = await web3.eth.getBalance(account);
      dispatch(setBalance(web3.utils.fromWei(balance, "ether")))
    }
  };

  const getChainId = async () => {
    const chainId = await web3.eth.getChainId(); 
    dispatch(seChain({ id: chainId }));
  };


  // const sendBscTransaction = async (txnParams: ITxnParams) => {
  //   const params = { ...txnParams, vlue: web3.utils.toWei(txnParams.value, "ether") }

  //   web3.eth.sendTransaction(txnParams, (error: any, hash: string) => {
  //     if (error) {
  //       if (error.code !== 4011) {
  //         message.error(error.message);
  //       }
  //       console.log("sendTransaction error", error);
  //     } else {
  //       message.success("send tx success: " + hash);
  //     }
  //   });
  // };

  // const signSolanaTransaction = async (instruction: string, callBack: (orderId: string, transactionData: string) => void) => {
  //   try {
  //     //  创建订单的时候获取instruction 
  //     const tx = Transaction.from(base58.decode(instruction));
  //     if ("signTransaction" in provider) {
  //       const signedTransaction = await provider.signTransaction(tx);
  //       const transactionData = signedTransaction.serialize();
  //       const base58Transaction = base58.encode(transactionData);
  //       //第二步发送 base58Transaction 到服务器 /orders:setTxid 接口
  //       callBack && callBack(base58Transaction, transactionData);
  //       // this.setOrderTixd(base58Transaction, transactionData)   
  //     }
  //   } catch (error: any) {
  //     message.error(error.message)
  //   }
  // }; 
}

export default Index;